import type { BlobObject, BlobPutOptions } from '@nuxthub/core'

export const useApiBlob = () => {
  // IMPORTANT: use useRequestFetch() to send cookies along when on the server (during SSR) which
  // $fetch does not do by default at the moment (pending merge https://github.com/nuxt/nuxt/issues/24813)
  const $fetch = useRequestFetch()

  const renameFile = (file: File, name: string) => new File([file], name, { type: file.type })

  const uploadFile = async (file: File, query: BlobPutOptions = {}) => {
    const formData = new FormData()
    formData.append('file', file)
    return $fetch<BlobObject>('/blob', {
      method: 'POST',
      query,
      body: formData,
    })
  }

  const removeFile = async (file: BlobObject) => {
    return $fetch(`/blob/${file.pathname}`, { method: 'DELETE' })
  }

  return {
    renameFile,
    uploadFile,
    removeFile,
  }
}
