<script setup lang="ts">
import { parseISO } from 'date-fns'

const props = defineProps<{
  datetime?: string // date or datetime format
  exact?: boolean
  displayHours?: boolean
  locale?: 'es' | 'en'
}>()

const date = computed(() => {
  if (!props.datetime) return

  const localDate = parseISO(props.datetime)

  const formatted = !props.displayHours ? localDate.toLocaleDateString() : localDate.toLocaleString()

  return {
    formatted,
    timeAgo: useLocaleTimeAgo(props.locale ?? 'es', localDate),
  }
})
</script>

<template>
  <span :title="date?.formatted">
    {{ props.exact ? date?.formatted : date?.timeAgo }}
  </span>
</template>
