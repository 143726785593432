import type { BlobObject } from '@nuxthub/core'

type TData = { id?: string, [k: string]: unknown }

export const useApiDB = <T extends TData>(tableName: string) => {
  // IMPORTANT: use useRequestFetch() to send cookies along when on the server (during SSR) which
  // $fetch does not do by default at the moment (pending merge https://github.com/nuxt/nuxt/issues/24813)
  const $fetch = useRequestFetch()
  const toast = useToast()

  const onSubmitPOST = async (row: Partial<T>) => {
    try {
      const result = await $fetch<T>(`/db/${tableName}`, {
        method: 'POST',
        body: row,
      })
      toast.add({
        title: 'Registro creado con éxito',
        icon: 'i-mdi-check-circle',
        color: 'green',
      })
      return result
    }
    catch (error) {
      toast.add({
        title: 'Error al crear el registro',
        description: error.message,
        icon: 'i-mdi-alert-circle',
        color: 'red',
        timeout: 7500,
      })
      console.error(error)
    }
  }

  const onSubmitPATCH = async (row: Partial<T>) => {
    try {
      const result = await $fetch<T>(`/db/${tableName}/${row.id}`, {
        method: 'PATCH',
        body: row,
      })
      toast.add({
        title: 'Registro actualizado con éxito',
        icon: 'i-mdi-check-circle',
        color: 'green',
      })
      return result
    }
    catch (error) {
      toast.add({
        title: 'Error al actualizar el registro',
        description: error.message,
        icon: 'i-mdi-alert-circle',
        color: 'red',
        timeout: 7500,
      })
      console.error(error)
    }
  }

  const onSubmitDELETE = async (rows: (Partial<T> & { id: T['id'] })[]) => {
    try {
      const result = await Promise.all(rows.map((row) => {
        const url = `/db/${tableName}/${row.id}`
        return $fetch(url, { method: 'DELETE' })
      }))
      // [blob] remove eventual associated files from blob:
      const { removeFile } = useApiBlob()
      await Promise.all(rows.map((row) => {
        Object.keys(row).forEach((key) => {
          if (key.startsWith('file')) {
            return removeFile(row[key] as BlobObject)
          }
          else if (['files'].includes(key)) {
            return Promise.all((row[key] as BlobObject[]).map(removeFile))
          }
        })
      }))
      toast.add({
        title: rows.length > 1 ? 'Registros eliminados con éxito' : 'Registro eliminado con éxito',
        icon: 'i-mdi-check-circle',
        color: 'green',
      })
      return result
    }
    catch (error) {
      toast.add({
        title:
          rows.length > 1 ? 'Error al eliminar los registros' : 'Error al eliminar el registro',
        description: error.message,
        icon: 'i-mdi-alert-circle',
        color: 'red',
        timeout: 7500,
      })
      console.error(error)
    }
  }

  const onSubmitPATCHMultiple = async (rows: Partial<T>[]) => {
    try {
      const result = await Promise.all(
        rows.map((row) => {
          const url = `/db/${tableName}/${row.id}`
          return $fetch(url, { method: 'PATCH', body: row })
        }),
      )
      toast.add({
        title: 'Registros actualizados con éxito',
        icon: 'i-mdi-check-circle',
        color: 'green',
      })
      return result
    }
    catch (error) {
      toast.add({
        title: 'Error al actualizar ciertos registros',
        description: error.message,
        icon: 'i-mdi-alert-circle',
        color: 'red',
        timeout: 7500,
      })
      console.error(error)
    }
  }

  return {
    onSubmitPOST,
    onSubmitPATCH,
    onSubmitDELETE,
    onSubmitPATCHMultiple,
  }
}
