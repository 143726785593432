import type { User } from '#auth-utils'

type ModuleOptions = User['modules'][keyof User['modules']]

// GET /db/:tableName
export const read = defineAbility((user: User, moduleOptions: ModuleOptions) => {
  if (!moduleOptions) return false
  return ['admin', 'edit', 'view'].includes(moduleOptions.role)
})

// POST /db/:tableName
export const create = defineAbility((user: User, moduleOptions: ModuleOptions) => {
  if (!moduleOptions) return false
  return ['admin', 'edit'].includes(moduleOptions.role)
})

// PATCH /db/:tableName/:id (including soft delete for archiving feature)
export const update = defineAbility((user: User, moduleOptions: ModuleOptions) => {
  if (!moduleOptions) return false
  return ['admin', 'edit'].includes(moduleOptions.role)
})

// DELETE /db/:tableName/:id
export const del = defineAbility((user: User, moduleOptions: ModuleOptions) => {
  if (!moduleOptions) return false
  return ['admin'].includes(moduleOptions.role)
})
